.meter {
    height: 20px;
    position: relative;
    background: #fff;
}

.meter>span {
    display: block;
    height: 100%;
    background-color: rgb(7, 29, 150);
    background-image: linear-gradient(center bottom,
            rgb(7, 29, 150) 37%,
            rgb(42, 76, 255) 69%);
    position: relative;
    overflow: hidden;
    transition: all 700ms ease;
}

.meter>span:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}